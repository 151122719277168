import React from 'react';
import Cards from '../Cards';

function Home() {
  return (
    <>
      <Cards />
    </>
  );
}

export default Home;
