//
// Get Article.
//
export const GET_ALL_ARTICLE_REQUESTED = 'GET_ALL_ARTICLE_REQUESTED';
export const GET_ARTICLE_REQUESTED     = 'GET_ARTICLE_REQUESTED';
export const GET_ARTICLE_SUCCESS       = 'GET_ARTICLE_SUCCESS';
export const GET_ARTICLE_FAILED        = 'GET_ARTICLE_FAILED';

//
// Post Article.
//
export const POST_ARTICLE_REQUESTED = 'POST_ARTICLE_REQUESTED';
export const POST_ARTICLE_SUCCESS   = 'POST_ARTICLE_SUCCESS';
export const POST_ARTICLE_FAILED    = 'POST_ARTICLE_FAILED';

//
// Edit Article.
//
export const EDIT_ARTICLE_REQUESTED = 'EDIT_ARTICLE_REQUESTED';
export const EDIT_ARTICLE_SUCCESS   = 'EDIT_ARTICLE_SUCCESS';
export const EDIT_ARTICLE_FAILED    = 'EDIT_ARTICLE_FAILED';

//
// Delete Article.
//
export const DELETE_ARTICLE_REQUESTED = 'DELETE_ARTICLE_REQUESTED';
export const DELETE_ARTICLE_SUCCESS   = 'DELETE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_FAILED    = 'DELETE_ARTICLE_FAILED';