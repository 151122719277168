import React, { useState, useEffect } from 'react';
// import { Button } from './Button';
import { Link } from 'react-router-dom';
// import './Navbar.css';

function Navbar() {
  // const [click, setClick] = useState(false);
  // const [button, setButton] = useState(true);

  // const handleClick = () => setClick(!click);
  // const closeMobileMenu = () => setClick(false);

  // const showButton = () => {
  //   if (window.innerWidth <= 960) {
  //     setButton(false);
  //   } else {
  //     setButton(true);
  //   }
  // };

  // useEffect(() => {
  //   showButton();
  // }, []);

  // window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          {/* <ul className={click ? 'nav-menu active' : 'nav-menu'}> */}
          <ul>
            <li className='nav-item'>
              <Link to='/'>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='post_article'>
                Post
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='test'>
                Test
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
